import type { GetStaticProps } from 'next'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useBrandInfoByKey } from '@wordup/lib-shop/utils'
import { SHOP_PATH } from '@libs-components/utils/routes'
import { Image } from '@libs-components/components/Image'
import { Text } from '@libs-components/components/text'
import { refreshI18nResourceBundle } from '@/libs/i18n'

function NotFoundPage() {
  const { t } = useTranslation()
  const brandPageKey = useBrandInfoByKey('pageKey')

  return (
    <div className='m-auto bg-white'>
      <div className='text-grayscale-800 m-auto flex h-[calc(100vh-90px)] w-full max-w-[800px] flex-col items-center justify-center px-5'>
        <Image
          src='https://public.wordup.com.tw/shop/assets/404_page_kv_D.png'
          alt='not found'
          width={600}
          height={230}
          className='h-auto min-w-full max-w-full'
        />
        <Text component='h1' variant='body_l_500' className='mb-1'>
          {t('404.title')}
        </Text>
        <Text component='p' variant='body_m_400' className='mb-4'>
          {t('404.message')}
        </Text>
        <Link
          href={SHOP_PATH.HOME(brandPageKey)}
          className='no-text-decoration bg-primary-500 flex h-10 w-24 items-center justify-center rounded-full text-white no-underline'
        >
          {t('common.to_homepage')}
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage

export const getStaticProps = (async ({ locale, defaultLocale }) => {
  locale ||= defaultLocale as string
  return {
    props: {
      ...(await refreshI18nResourceBundle({ locale, namespaces: ['common'] })),
    },
  }
}) satisfies GetStaticProps
